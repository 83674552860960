import React from 'react';
import './Menu.scss';
import NavItem, { IMenuItem } from '../Item';
import { isMobile } from 'react-device-detect';

interface IMenu {
  items: IMenuItem[];
  backgroundColor?: string;
  showOnMobile?: boolean;
}

const NavMenu = (props: IMenu) => {
  const renderComponent = () => {
    if (isMobile && !props.showOnMobile) {
      return <></>;
    } else {
      return (
        <div className="NavMenu"
          style={{ backgroundColor: props.backgroundColor || "var(--secondary)" }}>
          {props.items.map((x, i) => <NavItem key={i} {...x} />)}
        </div>
      );
    }
  }

  return renderComponent();
};

export default NavMenu;
