import React from 'react';
import './Item.scss';
import { Link } from '@reach/router';
import { Constants } from '../../constants';
import trackEvent from '../../services/analytics.service';

export interface IMenuItem {
  name: string;
  url: string;
}

const NavItem = (props: IMenuItem) => {
  const { name, url } = props;
  const handleClick = () => {
    trackEvent(name, {
      label: url,
      category: Constants.Segment.Category.Category,
      action: name
    })
  };
  return (
    <Link className="NavItem" to={url} onClick={handleClick}>{name}</Link>
  );
}

export default NavItem;