import React from 'react';
import './ResourceInfo.scss';
import { Link } from 'gatsby';
import { isMobile } from 'react-device-detect';
import Container from '../../../common/components/Container/Container';
import { Constants } from '../../../common/constants';
import trackEvent from '../../../common/services/analytics.service';

interface IResourceContent {
  logo: string;
  title: string;
  content: string;
  image: string;
  playStoreUrl: string;
  appleStoreUrl: string;
  externalLink: string;
}

const ResourceContent = (props: IResourceContent) => {
  const { logo, title, content, image, playStoreUrl, appleStoreUrl, externalLink } = props;
  const trackDownload = () => {
    trackEvent(Constants.Segment.Category.Access, {
      category: Constants.Segment.Category.Access,
      label: Constants.Segment.Label.Download,
      action: title
    });
  };
  const trackExit = (url: string) => {
    trackEvent(Constants.Segment.Action.Exit, {
      action: Constants.Segment.Action.Exit,
      label: url,
      category: Constants.Segment.Category.Navigation
    });
  };

  const trackGoogleDownload = () => {
    trackDownload();
    trackExit(playStoreUrl);
  };

  const trackAppleDownload = () => {
    trackDownload();
    trackExit(appleStoreUrl);
  };
  
  const trackBackToHome = () => {
    trackEvent(Constants.Segment.Action.BackToHome, {
      action: Constants.Segment.Action.BackToHome,
      label: window.location.pathname,
      category: Constants.Segment.Category.Navigation
    })
  }

  const googleIcon = playStoreUrl ? <a onClick={trackGoogleDownload} href={playStoreUrl} target="_blank" rel="noreferrer">
    <img src="/storeBadge-google.png" alt="apple store icon" className="Resource__store-icon" />
  </a> : <></>;
  const appleIcon = appleStoreUrl ? <a onClick={trackAppleDownload} href={appleStoreUrl} target="_blank" rel="noreferrer">
    <img src="/storeBadge-apple.png" alt="play store icon" className="Resource__store-icon" />
  </a> : <></>;
  const externalLinkButton = externalLink ? <a href={externalLink} target="_blank" rel="noreferrer" className="Button__resourceInfo">
    Learn More
  </a> : <></>;

  return (
    <Container>
      <div className="Resource">
        <div className="Resource__description">
          <p className="Resource__back">
            <Link to='/' onClick={trackBackToHome}>&lt; Back to home</Link>
          </p>
          <div className="Resource__title">
            <img src={logo} alt="resource logo" />
          </div>
          <div dangerouslySetInnerHTML={{ __html: content }} />
          <div className="Resource__app-icons">
            {appleIcon}
            {googleIcon}
            {externalLinkButton}
          </div>
        </div>
        <div className="Resource__image" style={{ backgroundImage: `url(${image})` }} />
      </div>
    </Container>
  )

};

export default ResourceContent;
