import React from "react"
import { graphql } from 'gatsby';
import NavMenu from '../common/components/Nav/Menu/Menu';
import SEO from '../core/components/SEO/seo';
import ResourceHeading from '../core/components/ResourceHeading/ResourceHeading';
import ResourceContent from "../core/components/ResourceInfo/ResourceInfo";
import HeroImage from "../core/components/HeroImage/HeroImage";
import { IMenuItem } from '../common/components/Nav/Item';

interface ResourcePage {
  data: {
    allWordpressWpJourney: {
      nodes: {
        name: string;
        slug: string;
      }[]
    }
    wordpressWpResources: {
      title: string;
      acf: {
        resource_title: string;
        resource_logo: {
          source_url: string;
        };
      }
      yoast_meta: {
        yoast_wpseo_title: string;
        yoast_wpseo_metadesc: string;
        yoast_wpseo_facebook_image: string;
        yoast_wpseo_facebook_description: string;
        yoast_wpseo_facebook_title: string;
        yoast_wpseo_twitter_title: string;
        yoast_wpseo_twitter_description: string;
        yoast_wpseo_twitter_image: string;
      }
      childWordPressAcfContent: {
        android_link: string;
        featured_image: {
          source_url: string;
        }
        content: string;
        ios_link: string;
        external_link: string;
      }
    }
  }
}
const ResourceListPage = (props: ResourcePage) => {
  const hero = <HeroImage backgroundImage="" quote="" author="" address2="" address1="" />;
  const { acf, childWordPressAcfContent } = props.data.wordpressWpResources;
  const { resource_title, resource_logo } = acf;
  const data = props.data;
  const seo = data.wordpressWpResources.yoast_meta
  const { android_link, content, featured_image, ios_link, external_link } = childWordPressAcfContent;
  return (
    <>
      <SEO
        title={`${data.wordpressWpResources.title} - My Walmart Cares`}
        description={seo.yoast_wpseo_metadesc}
        image={seo.yoast_wpseo_facebook_image ? seo.yoast_wpseo_facebook_image.source_url : ''}
        twitterDescription={seo.yoast_wpseo_twitter_description}
        twitterTitle={seo.yoast_wpseo_twitter_title}
        twitterImage={seo.yoast_wpseo_twitter_image ? seo.yoast_wpseo_twitter_image.source_url : ''}
        facebookDescription={seo.yoast_wpseo_facebook_description}
        facebookTitle={seo.yoast_wpseo_facebook_title}
      />
      <NavMenu items={props.data.allWordpressWpJourney.nodes.map(x => {
        const menuItem: IMenuItem = {
          url: `/categories/${x.slug}`,
          name: x.name
        }
        return menuItem;
      })} />
      <ResourceHeading title={resource_title} />
      {/* <HeroImage quote="" author="" address1="" address2="" backgroundImage="" /> */}
      <ResourceContent
        title={resource_title}
        appleStoreUrl={ios_link}
        content={content}
        image={featured_image && featured_image.source_url ? featured_image.source_url : ""}
        logo={resource_logo && resource_logo.source_url ? resource_logo.source_url : ""}
        playStoreUrl={android_link}
        externalLink={external_link} />
    </>
  )
};

export default ResourceListPage;
export const query = graphql`
  query ($id: String!){
    allWordpressWpJourney {
      nodes {
      name
        slug
      }
    }
    wordpressWpResources (id: {eq: $id}) {
      title
      acf {
        resource_title
        resource_logo {
          source_url
        }
      }
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_facebook_image
        yoast_wpseo_facebook_title
        yoast_wpseo_facebook_description
        yoast_wpseo_twitter_title
        yoast_wpseo_twitter_description
        yoast_wpseo_twitter_image
      }
      childWordPressAcfContent {
        content
        ios_link
        android_link
        external_link
        featured_image {
          source_url
        }
      }
    }
  }
`;
